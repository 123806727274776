import React from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'


export default function Menu() {

    return (
        <Navbar bg="dark" data-bs-theme="dark" collapseOnSelect expand="sm">
            <Container>
                <LinkContainer to={"/"}>
                    <Navbar.Brand className={"very-small lh-sm"}>
                        Herman Bartling<br/>SoftwareEntwickler
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto justify-content-end">
                        <LinkContainer to={"/experience"}>
                            <Nav.Link>Erfahrung</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/competence"}>
                            <Nav.Link>Kompetenz</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/contact"}>
                            <Nav.Link>Kontakt</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}
