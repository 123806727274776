import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default function HomePage() {
    return (
        <Container className={"mt-4"}>
            <Row className="justify-content-md-center">
                <Col className={"text-center"}>
                    Diese Seite enthält eine Zusammenfassung meiner Erfahrungen im Bereich Softwareentwicklung
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-4">
                <Col>
                    <h1>Guten Tag</h1>
                    <p>
                        Mein Name ist Herman Bartling und ich arbeite seit 2007 als Softwareentwickler.
                    </p>
                    <p>
                        Ich bin 2021 von Schweden nach Schleswig-Holstein in Deutschland gezogen und arbeite derzeit
                        seit Ende 2023 als Selbstständiger IT-consultant.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className={"mt-4"}>
                    <p>
                        <h2>Meine Sicht auf Softwareentwicklung</h2>
                        Meine technische Hauptkompetenz liegt im Aufbau von Backend-Systemen, die normalerweise die
                        Grundlage für die Systeme und Produkte bilden, die wir im IT-Geschäft liefern.
                    </p>
                    <p>
                        Ich bin jedoch der Meinung, dass ein gut funktionierendes Backend allein nicht viel wert ist,
                        wenn man dem Endbenutzer einen Mehrwert bieten möchte. Um ein erwartetes Ergebnis zu erzielen,
                        müssen alle Teile eines Systems so gestaltet sein, dass sie auf ausgewogene und stabile Weise
                        interagieren.
                    </p>
                    <p>
                        Dies kann schwierig sein, wenn das Endprodukt von Anfang an etwas vage ist, was die Details
                        betrifft. Darüber hinaus muss das Ergebnis natürlich auch innerhalb angemessener
                        Zeit auf den Markt kommen, wartbar, anpassbar und skalierbar sein.
                    </p>
                    <p>
                        Darin sehe ich die größte Herausforderung und Chance für die meisten Projekte, die
                        hauptsächlich auf von Software bereitgestellten Diensten abhängen.
                    </p>
                </Col>
            </Row>
        </Container>

    );
}
