import {Route, Routes} from "react-router-dom";
import LayoutPage from "./pages/LayoutPage";
import HomePage from "./pages/HomePage";
import NoPage from "./pages/NoPage";
import {AlertProvider} from "./components/shared/alert/AlertContext";
import ContactPage from "./pages/ContactPage";
import ExperiencePage from "./pages/ExperiencePage";
import CompetencePage from "./pages/CompetencePage";

export default function App() {

    return (
        <>
            <AlertProvider>
                <Routes>
                    <Route path="/" element={<LayoutPage/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="experience" element={<ExperiencePage/>}/>
                        <Route path="competence" element={<CompetencePage/>}/>
                        <Route path="contact" element={<ContactPage/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </AlertProvider>
        </>
    );
}
