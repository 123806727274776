import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default function NoPage() {
    return (
        <Container className={"mt-4"}>
            <Row className="justify-content-md-center">
            <Col>
                <h1 className={"text-center"}>Page not found</h1>
            </Col>
        </Row>
        </Container>

    );
}

