import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default function ContactPage() {
    return (
        <Container className={"mt-4"}>
            <Row className="justify-content-md-center">
                <Col>
                    <h1>Kontakt</h1>
                    <p>
                        Erreichen Sie mich unter: <code>herman.bartling@hbswe.de</code>
                    </p>
                </Col>
            </Row>
        </Container>

    );
}
